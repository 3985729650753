import React, { useState } from 'react';
import Start from '../Start/Start'
import CV from '../CV/CV'
import ImagePortfolio from '../ImagePortfolio/ImagePortfolio'
import './Content.scss'


const test = ["jobb.jpg", "sport.jpg"]
const imagePortfolio = <ImagePortfolio actives/>

const Content = () => {
  const [active, setActive] = useState(<div className="parent"><Start actives /><CV /><ImagePortfolio /></div>);
  const [state, setState] = useState("start");
  const [CVimages] = useState(test);
  const [IPimages] = useState(imagePortfolio);

  return (
    <div className="Menu">
      <div id="container">
        <button className={state === "start" ? "active learn-more" : "learn-more"} onClick={() => { setActive(<div className="parent"><Start actives /><CV /><ImagePortfolio /></div>); setState("start") }}>
          <span className="circle" aria-hidden="true">
            {state !== "start" && <span className="icon arrow"></span>}
          </span>
          <span className="button-text">Start</span>
        </button>
        <button className={state === "cv" ? "active learn-more" : "learn-more"} onClick={() => { setActive(<div className="parent"><Start /><CV actives img={CVimages} /><ImagePortfolio /></div>); setState("cv") }}>
          <span className="circle" aria-hidden="true">
            {state !== "cv" && <span className="icon arrow"></span>}
          </span>
          <span className="button-text">What I do</span>
        </button>
        <button className={state === "portfolio" ? "active learn-more" : "learn-more"} onClick={() => { setActive(<div className="parent"><Start /><CV />{IPimages}</div>); setState("portfolio") }}>
          <span className="circle" aria-hidden="true">
            {state !== "portfolio" && <span className="icon arrow"></span>}
          </span>
          <span className="button-text">Portfolio</span>
        </button>
      </div>{active}
    </div>
  );
}

export default Content