import React from 'react';
import './Start.scss';

const Start = (props) => {
  return props.actives ? (
    <div className={"Start" + (props && props.actives ? " actives-start" : "")}>
      <div id="first">
        <div className={"Start-text"}>
          <h1>Ludvig von Sydow</h1>
          <p>Probably the best guy in the world.. right?</p>
          <p>Nah not really. But I see myself as a pretty "reko" guy. </p>
          <p>Here you will find some information about me, what I do/done and a sneak peek of a hobby of mine. </p>
        </div>
      </div>
    </div>
    
  ):<div/>
}

export default Start;
