import React from 'react';
import './Card.scss'

const Card = (props) => {
  return (
    <div id="test">
      <a href={"#" + props.link} className="card">
        <div className="card__head">
          <div className={"card__image_" + props.className}></div>
          <div className="card__author">
            <div className="author">
              <span className="author__image">
                {props.icon}
              </span><div className="author__content">
                <p className="author__header">{props.heading}</p>
                <p className="author__subheader">{props.desc}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card__foot">
          <span className="card__link">Read more</span>
        </div>
        <div className="card__border"></div>
      </a>
    </div>
  );
}

export default Card