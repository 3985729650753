import React, { useEffect, useState } from 'react';
import './ImagePortfolio.scss';
import Image from '../Image/Image'
import axios from 'axios'

const ImagePortfolio = (props) => {

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && (images && images.length < 1)) {
      setLoading(true);
        try {
          for (var i = 1; i < 10; i++) {
            images.push(<Image key={i} className="image" srcBig={"http://www.ludvigvonsydow.com/uploads/" + i + ".JPG"} srcSmall={"http://www.ludvigvonsydow.com/uploads/" + i + ".JPG"} />)
          }
          setImages(images)
          setLoading(false)
        } catch (error) {
          setImages(images)
          setLoading(false)
        }
      }
    }, [images, loading])

  return (props.actives) ? (
    <div className={"ImagePortfolio" + (props && props.actives ? " actives-portfolio" : "")}>
      <div className={"ImagePortfolio-text "}>
        <h1>Image Portfolio</h1>
        <p>Probably the best portfolio in the world.. not really..</p>
        <p>BUT I love photography and especially landscapes. </p>
        <p>I aspire to get better and better as time goes and show off some of my favourites here. </p>
      </div>
      <div className="image-wrapper">
        {images}
      </div>
    </div>
  ) : <div />
}

export default ImagePortfolio;
