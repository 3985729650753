import React from 'react';
import './CV.scss';
import Card from '../Card/Card'
import { FaVolleyballBall } from 'react-icons/fa'
import { FiBriefcase } from 'react-icons/fi'
import { IoSchoolOutline } from 'react-icons/io5'

const CV = (props) => {
  return props.actives ? (
    <div className={"CV " + (props && props.actives ? " actives-cv" : "")}>
      <div className="cv-menu">
        <div className={"CV-text "}>
          <h1>What I do</h1>
          <p>I guess you are sitting there thinking <br /> - who is this guy? <br /> - what does he do? <br /> - what has he done?</p>
          <p>Well I hope you will find your answers right here. If there are any questions you can just send an <a className="link" href="mailto: ludvig@vonsydow.com">email</a></p>
        </div>
        <div className="cards">
          <Card link="work" icon={<FiBriefcase />} heading={"Work"} desc={"What I've done professionally"} className="work" />
          <Card link="sport" icon={<FaVolleyballBall />} heading={"Sport"} desc={"What I've accomplished in sports"} className="sport" />
          <Card link="education" icon={<IoSchoolOutline />} heading={"Education"} desc={"What I've sudied"} className="education"/>
        </div>
      </div>
      <div id="work">
        <h1>My work</h1>
        <p>massor med text om mitt jobb och annat jag gjort</p>
      </div>
      <div id="sport">
        <h1>Sports</h1>
        <p>Spelat volleyboll på elitnivå blablabla</p>
      </div>
      <div id='education'>
        <h1>Education</h1>
        <p>pluggat på Lunds univeritet, computerscience</p>
      </div>
    </div>
  ) : <div />
}

export default CV;
