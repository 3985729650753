
import React, { useState } from 'react';
import './Image.scss';
import { BiLoaderAlt } from 'react-icons/bi'

const Image = (props) => {
  const [clicked, setClick] = useState(false);
  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(true);

  return (
    available && 
    <div>
      <img className="image" src={props.srcSmall} onError={() => {setLoading(false); setAvailable(false)}} onLoad={() => setLoading(false)} onClick={() => setClick(!clicked)} alt="noImg" />
      {loading && <BiLoaderAlt className="loader" />}
      {clicked && (
        <div>
          <dialog className="dialog" open onClick={() => setClick(!clicked)}>
            <img className="bigImage" src={props.srcBig} onLoad={() => <BiLoaderAlt className="loader" />} onClick={() => setClick(!clicked)} alt="dialogImg" /></dialog>
        </div>
      )}
    </div>
  )
}


export default Image;
